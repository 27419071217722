import { withPrefix } from 'gatsby'

export const getLastPathElem = (path: string) => {
  const arr = path.split('/')
  return arr[arr.length - 1]
}

export const customNavigate = (path: string) => {
  document.location.href = withPrefix(path)
}
